<template>
  <span class="regular-12 badge w-100 m-2 primary" v-if="value.is_change">
    {{ $t("orderAfterStatus.change") }}
  </span>
  <span class="regular-12 badge w-100 m-2 warning text-black" v-if="value.is_refund">
    {{ $t("orderAfterStatus.return") }}
  </span>
  <span class="regular-12 badge w-100 m-2 failed" v-if="value.is_refused">
    {{ $t("orderAfterStatus.refused") }}
  </span>
  <span class="regular-12 badge w-100 m-2 failed" v-if="value.is_deny">
    {{ $t("orderAfterStatus.denied") }}
  </span>
</template>
<script>
export default {
  name: "OrderAfterStatus",
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
